import {MutationActions, mutateNavigationData} from '@hy-vee/global-navigation';

import {graphqlClient} from '../../graphql-client';
import {updateCartCacheAfterCreate, updateCartCacheAfterDelete} from '../../../utils/cart-mutation-helpers';
import {createCartItemMutation, deleteCartItemMutation, updateCartItemMutation} from '../cart-item-mutations';

// eslint-disable-next-line require-await
export const createCartItemAction = async (createCartItemInput, customer, setLoading) => {
    const client = graphqlClient();

    return client.mutate({
        mutation: createCartItemMutation,
        update: (cache, data) => updateCartCacheAfterCreate(cache, data.data.createCartItem, customer, setLoading),
        variables: {
            createCartItemInput,
            customerId: customer.customerId,
        },
    });
};

export const updateCartItemAction = async (updateCartItemInput) => {
    const client = graphqlClient();
    const changeQuantityAction = MutationActions.changeCartItemQuantity(updateCartItemInput.cartItemId.toString(), updateCartItemInput.quantity);

    await mutateNavigationData(changeQuantityAction);

    return client.mutate({
        errorPolicy: 'all',
        mutation: updateCartItemMutation,
        variables: {
            cartItemInput: updateCartItemInput,
        },
    });
};

export const deleteCartItemAction = async (cartItemId, customerId) => {
    const client = graphqlClient();
    const deleteItemAction = MutationActions.removeCartItem(String(cartItemId));

    await mutateNavigationData(deleteItemAction);

    return client.mutate({
        errorPolicy: 'all',
        mutation: deleteCartItemMutation,
        update: (cache) => updateCartCacheAfterDelete(cache, cartItemId),
        variables: {
            cartItemId,
            customerId,
        },
    });
};
