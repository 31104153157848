import {connect} from 'react-redux';

import withRedux from '../../utils/with-redux';
import Page from '../../components/Page';
import withMixedAuthenticationCheck from '../../utils/with-mixed-authentication-check';
import FuelSaver from '../../views/Fuel-saver';

const PerksPage = ({customer, isAuthenticated, store}) => <Page
    Component={FuelSaver}
    customStyles
    customer={customer}
    description="Hy-Vee PERKS is a free and easy program for Hy-Vee shoppers. Access Exclusive PERKS Prices, earn discounts on gas, and save more with exclusive deals."
    isAuthenticated={isAuthenticated}
    store={store}
    title="Hy-Vee PERKS"
    url="https://hy-vee.com/perks"
/>;

const mapStateToProps = (state) => ({
    customer: state.customer,
    isAuthenticated: state.isAuthenticated,
    store: state.store,
});

export default withRedux(withMixedAuthenticationCheck(connect(mapStateToProps)(PerksPage)));
