// eslint-disable-next-line no-unused-vars
import React from 'react';
import {Text} from '@hy-vee/design-system';

import SanityImage from '../../Image';
import {displayAuthenticatedValue} from '../../../utils/value-helpers';

import LoggedOut from './loggedComponents/logged-out-actions';
import LoggedIn from './loggedComponents/logged-in-actions';
import styles from './landing-hero.module.css';
import LoggedInActive from './loggedComponents/logged-in-active-actions';

const LandingHero = (props) => {
    const {isAuthenticated, hasFuelSaver} = props.contextProps.userFlags;
    const title = displayAuthenticatedValue(isAuthenticated, hasFuelSaver, props.title, props.loggedInTitle, props.loggedInActiveTitle);
    const description = displayAuthenticatedValue(isAuthenticated, hasFuelSaver, props.description, props.loggedInDescription, props.loggedInActiveDescription);

    return (
        <div
            className={styles.hero}
            data-testid="hero"
        >
            <div className={styles.heroImageBackground} />
            <div className={styles.heroContentCentering}>
                <div className={styles.heroContentWrapper}>
                    <div className={styles.heroContent}>
                        <div className={styles.microCopy}>
                            <p>{props.microcopy}</p>
                        </div>
                        <Text
                            className={styles.title}
                            textStyle="heading1"
                        >
                            {title}
                        </Text>
                        <Text textStyle="body2">
                            {description}
                        </Text>
                        {!isAuthenticated && (<LoggedOut
                            data-testid="logged-out-actions"
                            loggedOut={props.loggedOut} />)}
                        {isAuthenticated && !hasFuelSaver && (<LoggedIn
                            data-testid="logged-in-actions"
                            loggedIn={props.loggedIn} />)}
                        {isAuthenticated && hasFuelSaver && (<LoggedInActive
                            data-testid="logged-in-active-actions"
                            loggedInActive={props.loggedInAndActive} />)}
                    </div>
                    <div className={styles.coinsImage}>
                        <SanityImage
                            {...props.image}
                            setMaxSize={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingHero;
