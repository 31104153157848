import queryString from 'query-string';

const getLegacyLogoutUrl = () => '/account/login.aspx?logout=1';
const COM_LOGIN_PATH = '/account/login.aspx';
const LOGIN_PATH = '/perks/login';
const LOGOUT_PATH = '/perks/logout';

const returnUrl = (pathWithQuery) =>
    queryString.stringify({
        redirect: pathWithQuery,
    });

export const getLoginUrlWithRedirect = (path) => `${LOGIN_PATH}?${returnUrl(path)}`;

export const getLogoutUrl = () => `${LOGOUT_PATH}?${returnUrl(getLegacyLogoutUrl())}`;

export const getComLoginUrlWithRedirect = (retUrl) => `${COM_LOGIN_PATH}?${queryString.stringify({retUrl})}`;
