// eslint-disable-next-line no-unused-vars
import React from 'react';
import {Button, Link} from '@hy-vee/design-system';

import styles from '../landing-hero.module.css';

const LoggedOutActions = ({loggedOut}) => (
    <div className={styles.loggedOutActions}>
        <div data-testid="logged-out-actions">
            <a
                data-testid="login-to-signup-link"
                href={loggedOut.primaryAction.href}
            >
                <Button className={styles.primaryActionButton}>
                    {loggedOut.primaryAction.text}
                </Button>
            </a>
        </div>
        <div>
            {loggedOut.secondaryAction.prefixText}
            {' '}
            <Link
                className={styles.heroLink}
                data-testid="login-link"
                href={loggedOut.secondaryAction.href}
                style={{textDecoration: 'underline'}}
            >
                {loggedOut.secondaryAction.text}
            </Link>
        </div>
    </div>
);

export default LoggedOutActions;
