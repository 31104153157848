import {SubstitutionPreference} from '@hy-vee/shared-utils-aisles-online';

import {addToCartDataLayer, removeFromCartDataLayer} from '../services/analytics-service';
import {EACH, POUND} from '../enums/aisles-online-quantity-types';
import {
    createCartItemAction,
    deleteCartItemAction,
    updateCartItemAction,
} from '../graphql/mutations/mutation-actions/cart-item-mutation-actions';

export const onChangeAmountInCart = (
    cartItem,
    product,
    storeProduct,
    customer,
    quantity,
    setLoading,
) => {
    if (!cartItem) {
        addToCartDataLayer(product, storeProduct, quantity);

        const createCartItemInput = {
            productId: Number(product.productId),
            quantity,
            quantityType: storeProduct.isWeighted && !product.averageWeight ? POUND : EACH,
            storeProductId: Number(storeProduct.storeProductId),
            substitutionPreference: SubstitutionPreference.BEST_MATCH,
        };

        return createCartItemAction(createCartItemInput, customer, setLoading);
    }

    const quantityDelta = Math.abs(quantity - cartItem.quantity);

    if (cartItem.quantity < quantity) {
        addToCartDataLayer(product, storeProduct, quantityDelta);
    } else {
        removeFromCartDataLayer(product, quantityDelta);
    }

    if (quantity === 0) {
        setLoading(false);

        return deleteCartItemAction(Number(cartItem.cartItemId), customer.customerId);
    }

    setLoading(false);

    return updateCartItemAction({
        cartItemId: Number(cartItem.cartItemId),
        quantity,
    });
};
