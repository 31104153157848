import {Text, Button, Link} from '@hy-vee/design-system';
import {PortableText} from '@portabletext/react';

import styles from '../bottom-call-to-action.module.css';

const CTAContent = ({content, flags}) => {
    const {isAuthenticated, hasFuelSaver} = flags;

    return (
        <div
            className={styles.contentWrap}
            data-testid="content">
            <div
                className={styles.headerWrap}
                data-testid="title">
                <div>
                    <Text className={styles.header}>
                        {<PortableText value={content?.contentRaw[0]} />}
                    </Text>
                </div>
                <div>
                    <a href={content?.primaryAction.href}>
                        <Button className={styles.primaryAction}>
                            {content?.primaryAction.text}
                        </Button>
                    </a>
                </div>
            </div>
            <div
                className={styles.mainContent}
                data-testid="main-content">
                <div
                    className={styles.infoSection}
                    data-testid="leftSection">
                    <Text
                        className={styles.subHeader}
                        data-testid="left-subHeader">
                        {<PortableText value={content?.contentRaw[1]} />}
                    </Text>
                    <Text
                        className={styles.body}
                        data-testid="left-body"
                        textStyle="body2">
                        {<PortableText value={content?.contentRaw[2]} />}
                    </Text>
                    {isAuthenticated && hasFuelSaver &&
                        <Link
                            className={styles.ctaLink}
                            data-testid="left-secondaryAction"
                            href={content?.secondaryAction[0]?.href}
                            style={{textDecoration: 'underline'}}
                        >
                            {content?.secondaryAction[0]?.text}
                        </Link>}
                </div>
                <hr className={styles.verticalLine} />
                <div
                    className={styles.infoSection}
                    data-testid="middleSection">
                    <Text
                        className={styles.subHeader}
                        data-testid="middle-subHeader">
                        {<PortableText value={content?.contentRaw[3]} />}
                    </Text>
                    <Text
                        className={styles.body}
                        data-testid="middle-body"
                        textStyle="body2">
                        {<PortableText value={content?.contentRaw[4]} />}
                    </Text>
                    {isAuthenticated && hasFuelSaver &&
                        <Link
                            className={styles.ctaLink}
                            data-testid="middle-secondaryAction"
                            href={content?.secondaryAction[1]?.href}
                            style={{textDecoration: 'underline'}}
                        >
                            {content?.secondaryAction[1]?.text}
                        </Link>}
                </div>
            </div>
        </div>
    );
};

export default CTAContent;
