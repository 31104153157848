// eslint-disable-next-line no-unused-vars
import React, {useMemo} from 'react';
import {Accordion, Modal, Spinner} from '@hy-vee/web-core';
import {Text, SecondaryButton} from '@hy-vee/design-system';
import {useQuery} from '@apollo/client';

import {getPartnerLocations} from '../../../graphql/queries/sanity-queries';
import {TWO_LEGGED_AUTH_TYPE} from '../../../enums/auth-types';
import {stateNamesByAbbreviations} from '../../../utils/address-helpers';

import styles from './redemption-locations-modal.module.css';

// Not really unsafe. Regex linter has issues with from-end "$" regexes having skipped "?" matches.
// eslint-disable-next-line security/detect-unsafe-regex
const stateNameAbbreviationRegex = /([\w]{2})[\s][\d]{5}(?:[-]?[\d]{4})?$/u;
const UnknownStateName = 'Other Locations';

const partnerLocationModalTitle = (partner, partnerData, loading, error) => {
    if (error) {
        return `Could not load ${partner.partnerName} stations`;
    } else if (loading) {
        return `Loading ${partner.partnerName} Stations…`;
    }

    return partnerData?.title ?? `Participating ${partner.partnerName} Stations`;
};

const buildLocationsByState = (partnerLocations = []) => {
    let locationsByStateName = {};
    const getLocationsByParsedStateAbbreviation = (parsedAbbreviation) => {
        const stateName = stateNamesByAbbreviations[parsedAbbreviation] ?? UnknownStateName;
        let stateLocations = locationsByStateName[stateName];

        if (!stateLocations) {
            stateLocations = {
                locations: [],
                stateName,
            };

            locationsByStateName = {
                ...locationsByStateName,
                [stateName]: stateLocations,
            };
        }

        return stateLocations;
    };

    partnerLocations.forEach((partnerLocation) => {
        const stateAbbreviation = partnerLocation.cityStateZip.match(stateNameAbbreviationRegex)?.[1];
        const stateLocations = getLocationsByParsedStateAbbreviation(stateAbbreviation);

        stateLocations.locations = [
            ...stateLocations.locations,
            partnerLocation,
        ];
    });

    Object.values(locationsByStateName).forEach((state) =>
        state.locations.sort((locationA, locationB) => locationA.cityStateZip.localeCompare(locationB.cityStateZip)));

    return [...Object.values(locationsByStateName)]
        .sort((locationA, locationB) => locationA.stateName === UnknownStateName ? 1 : locationA.stateName.localeCompare(locationB.stateName));
};

const RedemptionLocationsModal = ({partner, onClose}) => {
    const {
        data,
        loading,
        error,
    } = useQuery(getPartnerLocations, {
        variables: {
            authType: TWO_LEGGED_AUTH_TYPE,
            partnerId: partner?._id,
        },
    });
    const partnerData = data?.allCmsLoyaltyWebPerksParticipatingFuelLocations?.[0];
    const locationsByState = useMemo(() => buildLocationsByState(partnerData?.fuelLocations), [partnerData]);

    return (
        <Modal
            onClose={onClose}
            title={partnerLocationModalTitle(partner, partnerData, loading, error)}
        >
            {loading ?
                <div
                    className={styles.partnerLocationsModalLoading}
                    data-testid="fs-redemption-location-spinner"
                >
                    <div className={styles.partnerLocationsModalLoadingSpinnerContainer}>
                        <Spinner />
                    </div>
                </div>
                :
                <div className={styles.partnerLocationsModalContent}>
                    <div>{partnerData?.subtitle}</div>
                    <div className={styles.partnerLocationsModalLocations}>
                        {locationsByState.map((stateLocations) =>
                            <div
                                className={styles.partnerLocationsModalState}
                                key={stateLocations.stateName}
                            >
                                <Accordion
                                    className={styles.partnerLocationsModalStateName}
                                    openByDefault={locationsByState.length <= 1}
                                    title={stateLocations.stateName}
                                >
                                    <div className={styles.partnerLocationsModalStateLocations}>
                                        {stateLocations.locations.map((location) =>
                                            <div
                                                className={styles.partnerLocationsModalStateLocation}
                                                key={location._id}
                                            >
                                                <Text
                                                    textStyle="body3"
                                                >
                                                    <strong>{location.address}</strong>
                                                </Text>
                                                <Text
                                                    textStyle="body3"
                                                >
                                                    {location.cityStateZip}
                                                </Text>
                                            </div>,
                                        )}
                                    </div>
                                </Accordion>
                            </div>,
                        )}
                    </div>
                    <div className={styles.partnerLocationsModalFooter}>
                        <SecondaryButton
                            onClick={onClose}
                        >
                            {'Close'}
                        </SecondaryButton>
                    </div>
                </div>}
        </Modal>
    );
};

export default RedemptionLocationsModal;
