import {useQuery} from '@apollo/client';

import {TWO_LEGGED_AUTH_TYPE} from '../enums/auth-types';
import {getLocationByBusinessCodes} from '../graphql/queries/location-queries';

export const useLocationIds = (storeCode) => {
    const businessCodes = [];

    if (storeCode && storeCode.length !== 0) {
        businessCodes.push(storeCode);
    }

    const {data, loading} = useQuery(getLocationByBusinessCodes,
        {
            errorPolicy: 'all',
            skip: businessCodes.length === 0,
            variables: {
                authType: TWO_LEGGED_AUTH_TYPE,
                businessCodes,
            },
        },
    );

    if (loading || !data?.locationsByBusinessCodes?.length) {
        return {
            locationIdLoading: loading,
            locationIds: [''],
        };
    }

    return {
        locationIdLoading: loading,
        locationIds: data.locationsByBusinessCodes.map((location) => location.locationId),
    };
};
