// eslint-disable-next-line no-unused-vars
import React from 'react';
import {Button} from '@hy-vee/web-core/lib/components/button';
import {Link} from '@hy-vee/design-system';

import styles from '../landing-hero.module.css';

const LoggedInActiveActions = ({loggedInActive}) => (
    <div className={styles.loggedInActiveActions}>
        <div data-testid="logged-in-active-actions">
            <a
                data-testid="view-your-rewards-link"
                href={loggedInActive.primaryAction.href}
            >
                <Button className={styles.primaryActionButton}>
                    {loggedInActive.primaryAction.text}
                </Button>
            </a>
        </div>
        <div>
            {loggedInActive.secondaryAction.prefixText}
            {/* eslint-disable-next-line react/forbid-elements */}
            <br />
            <Link
                className={styles.heroLinkActive}
                data-testid="login-link"
                href={loggedInActive.secondaryAction.href}
                style={{textDecoration: 'underline'}}
            >
                {loggedInActive.secondaryAction.text}
            </Link>
        </div>
    </div>
);

export default LoggedInActiveActions;
