import styles from './bottom-call-to-action.module.css';
import CTAContent from './components/content';

const BottomCallToAction = (props) => {

    const {isAuthenticated, hasFuelSaver} = props.contextProps.userFlags;

    let content = props.content?.[0];

    if (isAuthenticated) {
        content = hasFuelSaver ? props.content?.[2] : props.content?.[1];
    }

    return (
        <div
            className={styles.ctaWrap}
            data-testid="call-to-action-wrap">
            <img
                alt="call to action logo"
                className={styles.logo}
                src={props.logo?.webpSrc1x}
            />
            <CTAContent
                content={content}
                flags={props.contextProps.userFlags}
            />
        </div>
    );
};

export default BottomCallToAction;
