import getConfig from 'next/config';

import {useLocationIds} from '../../hooks/use-location-ids';
import {useAislesOnlineCart} from '../../hooks/use-aisles-online-cart';
import {FuelSaverContent} from '../../components/fuel-saver/fuel-saver-content';

const FuelSaver = ({customer = {}}) => {
    const {data: cart} = useAislesOnlineCart(customer);
    const {locationIds} = useLocationIds(cart?.store?.storeId ?? getConfig().publicRuntimeConfig.defaultStoreId);
    const storeId = cart?.store?.storeId ?? getConfig().publicRuntimeConfig.defaultStoreId;

    return (
        <FuelSaverContent
            customer={customer}
            locationIds={locationIds}
            storeId={storeId}
        />
    );
};

export default FuelSaver;
