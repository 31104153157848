import {gql} from '@apollo/client';

import {cartItemFragment} from '../fragments/cart-fragments';

export const createCartItemMutation = gql`
    mutation createCartItemProductMutation(
        $customerId: Int!
        $createCartItemInput: createCartItemInput!
    ) {
        createCartItem(customerId: $customerId, cartItemInput: $createCartItemInput) {
            ...ICartItem
        }
    }
    ${cartItemFragment}
`;

export const deleteCartItemMutation = gql`
    mutation DeleteCartItemMutation($customerId: Int!, $cartItemId: Int!) {
        deleteCartItem(customerId: $customerId, cartItemId: $cartItemId)
    }
`;

export const updateCartItemMutation = gql`
    mutation UpdateCartItemMutation($customerId: Int!, $cartItemInput: updateCartItemInput!) {
        updateCartItem(customerId: $customerId, cartItemInput: $cartItemInput) {
            cartItemId
            quantity
        }
    }
`;
