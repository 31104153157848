import {MutationActions, mutateNavigationData} from '@hy-vee/global-navigation';

import {getAislesOnlineCart} from '../graphql/queries/cart-queries';

export const updateCartCacheAfterCreate = async (client, createdCartItem, customer, setLoading) => {
    const {customerId} = customer;

    const changeQuantityAction = MutationActions.addCartItemById(createdCartItem.cartItemId);

    await mutateNavigationData(changeQuantityAction);

    const clientData = client.readQuery({
        query: getAislesOnlineCart,
        variables: {
            customerId: Number(customerId),
        },
    });

    const cart = clientData?.carts[0];

    if (cart) {
        const updatedClientData = {
            ...clientData,
            carts: [{
                ...cart,
                cartItems: [...cart.cartItems, createdCartItem],
            }],
        };

        client.writeQuery({
            data: updatedClientData,
            query: getAislesOnlineCart,
        });
    }

    setLoading(false);
};

export const updateCartCacheAfterDelete = (cache, cartItemId) => {
    const cartItemToDelete = cache.identify({
        __typename: 'cartItem',
        cartItemId,
    });

    cache.evict({id: cartItemToDelete});
    cache.gc();
};
