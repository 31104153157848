// eslint-disable-next-line no-unused-vars
import React from 'react';
import {Button} from '@hy-vee/web-core/lib/components/button';

import styles from '../landing-hero.module.css';

const LoggedInActions = ({loggedIn}) => (
    <div
        className={styles.loggedInActions}
        data-testid="logged-in-actions">
        <a
            data-testid="sign-up-for-fuel-saver-link"
            href={loggedIn.primaryAction.href}
        >
            <Button className={styles.primaryActionButton}>
                {loggedIn.primaryAction.text}
            </Button>
        </a>
    </div>
);

export default LoggedInActions;
