// eslint-disable-next-line no-unused-vars
import React from 'react';
import {NextSeo} from 'next-seo';
import PageNotFound from '@hy-vee/nav/lib/screens/404';

const NotFoundPage = () => (
    <>
        <NextSeo title="404 - Hy-Vee" />
        <PageNotFound homePageUrl="/" />
    </>
);

export default NotFoundPage;
