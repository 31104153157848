import {Link, Text} from '@hy-vee/design-system';

import {isInternalUrl} from '../../../utils/url-helpers';

import styles from './redemption-locations.module.css';

const FuelSaverLandingRedemptionLocations = ({contextProps, ...props}) => {
    const {headerText, bodyText, imageUrl, partner: internalPartner, partnerUrlText: internalPartnerUrlText, externalPartnersHeaderText, externalPartners} = props;

    const partnerLink = (partner, displayText) => {
        const linkContent = displayText ?? partner.partnerLocationDisplayAction.text;
        const partnerAction = partner.partnerLocationDisplayAction;

        return partnerAction.destination ?
            <Link
                className={styles.redemptionLocationsExternalLocationsPartnerLink}
                externalIcon={!isInternalUrl(partnerAction.destination)}
                href={partnerAction.destination}
            >
                {linkContent}
            </Link>
            :
            <Link
                className={styles.redemptionLocationsExternalLocationsPartnerAction}
                onClick={() => contextProps.callbacks.actionButtonClickCallback(partnerAction, partner)}
            >
                {linkContent}
            </Link>;
    };

    return (
        <div className={styles.redemptionLocations}>
            <div className={styles.redemptionLocationsContent}>
                <div className={styles.redemptionLocationsPrimaryPartner}>
                    <img
                        alt={`${internalPartner.partnerName} logo`}
                        className={styles.redemptionLocationsPrimaryPartnerLogo}
                        src={imageUrl}
                    />
                    <div className={styles.redemptionLocationsPrimaryPartnerInfo}>
                        <Text
                            className={styles.redemptionLocationsPrimaryPartnerInfoHeader}
                            textStyle="heading4"
                        >
                            {headerText}
                        </Text>
                        <Text
                            className={styles.redemptionLocationsPrimaryPartnerInfoBody}
                            textStyle="body2"
                        >
                            {bodyText}
                        </Text>
                        {partnerLink(internalPartner, internalPartnerUrlText)}
                    </div>
                </div>
                {externalPartners.length &&
                    <div className={styles.redemptionLocationsExternalLocations}>
                        <Text
                            className={styles.redemptionLocationsExternalLocationsHeader}
                            textStyle="body2"
                        >
                            {externalPartnersHeaderText}
                        </Text>
                        <div className={styles.redemptionLocationsExternalLocationsPartners}>
                            {externalPartners.map((externalPartner) => (
                                <div
                                    className={styles.redemptionLocationsExternalLocationsPartner}
                                    key={externalPartner._id}
                                >
                                    <div className={styles.redemptionLocationsExternalLocationsPartnerLogoWrapper}>
                                        <img
                                            alt={`${externalPartner.partnerName} logo`}
                                            className={styles.redemptionLocationsExternalLocationsPartnerLogo}
                                            src={externalPartner.partnerLogoUrl}
                                        />
                                    </div>
                                    {partnerLink(externalPartner, undefined)}
                                </div>
                            ))}
                        </div>
                    </div>}
            </div>
        </div>
    );
};

export default FuelSaverLandingRedemptionLocations;
